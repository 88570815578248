import request from '../utils/request'

export  function getAccounts(){
    return request({
        method: 'get',
        url: '/api/v1/user/account'
    })
}

// http://192.168.10.254/api/v1/finance/info/trans?exchange_name=okex&sub_account_id=61c18b54629957a2cdfe79b9&currency=USDT
export function getAccountRest(params){
    return request({
        method: 'get',
        url: '/api/v1/finance/info/trans',
        params,
    })
}

export function getAccountRestGuiji(params){
    return request({
        method: 'get',
        url: '/api/v1/finance/info/absorption',
        params,
    })
}

export function accountGuiji(body){
    return request({
        method: 'put',
        url: '/api/v1/finance',
        data: body
    })
}


export function updateTrans(body){
    return request({
        method: 'put',
        url: '/api/v1/finance/trans',
        data: body
    })
}

export function getSymbolPosition(exchange_name,account_id){
    return request({
        method: 'get',
        url: '/api/v1/symbol/all_position',
        params: {
            exchange_name,
            account_id,
        }
    })
}

